import { BiX } from 'react-icons/bi'
import { useEffect, useState } from 'react'
import styles from './NotificationBanner.module.scss'

interface NotificationBannerProps {
  storageKey: string
  children: React.ReactNode
}

export default function NotificationBanner({
  storageKey,
  children,
}: NotificationBannerProps) {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const isDismissed = sessionStorage.getItem(storageKey)
    setIsVisible(!isDismissed)
  }, [storageKey])

  const handleClose = () => {
    sessionStorage.setItem(storageKey, 'true')
    setIsVisible(false)
  }

  if (!isVisible) return null

  return (
    <div className={styles.banner}>
      <button
        onClick={handleClose}
        className={styles.closeButton}
        aria-label="Close banner"
      >
        <BiX />
      </button>
      <div className={styles.content}>
        <span className={styles.message}>{children}</span>
      </div>
    </div>
  )
}
