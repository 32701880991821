import React, { FC } from 'react'
import classNamesBind from 'classnames/bind'
import css from './ExternalLink.module.scss'
import Icon, { Icons } from '../Icon/Icon'

export interface ExternalLinkProps {
  children: React.ReactNode
  to?: string
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  withIcon?: boolean
  secondary?: boolean
  className?: string
}

const classNames = classNamesBind.bind(css)

const ExternalLink: FC<ExternalLinkProps> = ({
  children,
  to,
  onClick,
  withIcon = false,
  secondary = false,
  className,
}: ExternalLinkProps) => {
  return (
    <a
      className={classNames('external-link', { secondary }, className)}
      href={to}
      onClick={onClick}
      target="_blank"
      rel="noreferrer"
    >
      {withIcon && (
        <div className={classNames('icon')}>
          <Icon icon={Icons.Link} />
        </div>
      )}
      {children}
    </a>
  )
}

export default ExternalLink
